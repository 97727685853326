































































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { ApiDto } from '@portals/shared/admin/ApiDto';
import Vue from 'vue';

import Badge from '@/components/Badge.vue';
import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchApis } from '@/service/apiService';

type Data = {
  apis: ApiDto[];
  loading: boolean;
  selectedApi: Record<string, unknown> | null;
  create: boolean;
};

export default Vue.extend({
  name: 'list-api',
  components: {
    Badge,
    Loader,
    DnbIcon,
    TableComponent,
    TableColumn,
  },
  data(): Data {
    return {
      apis: [],
      loading: false,
      selectedApi: null,
      create: false,
    };
  },
  mounted() {
    this.loadAPIs();
  },
  methods: {
    colorOfStatus(status: string) {
      switch ((status || '').toLowerCase()) {
        case 'production':
        case 'live-mode':
          return 'violet';

        case 'sandbox':
        case 'test-mode':
          return 'green';

        default:
          return 'gray';
      }
    },
    async loadAPIs() {
      this.loading = true;
      this.apis = await fetchApis();
      this.loading = false;
    },
    async onAPIUpdate(key: string) {
      if (key === 'updated') {
        await this.loadAPIs();
      }
    },
    onRowClick(row: { data: ApiDto }) {
      this.$router.push(`/apis/${row.data.id}/edit`);
    },
  },
});
