import {
  ApiDto,
  ApiInputDto,
  ApiWithEnvironmentsDto,
} from '@portals/shared/admin/ApiDto';

import { request } from './request';

export async function fetchApis(): Promise<ApiWithEnvironmentsDto[]> {
  const response = await request<ApiWithEnvironmentsDto[]>({
    url: 'api',
  });
  return response.data;
}

export async function fetchApi(apiId: string): Promise<ApiWithEnvironmentsDto> {
  const response = await request<ApiWithEnvironmentsDto[]>({
    url: 'api',
  });
  const api = response.data.find((api) => api.id === apiId);
  if (api) {
    return api;
  }
  throw new Error('Could not find Api');
}

export async function saveApi(api: ApiInputDto): Promise<ApiDto> {
  const response = await request<ApiDto>({
    url: 'api',
    method: 'POST',
    data: api,
  });

  return response.data;
}

export async function deleteApi(id: string): Promise<void> {
  await request<ApiDto>({
    url: `api/${id}`,
    method: 'DELETE',
  });
}
