




import Vue from 'vue';
export default Vue.extend({
  name: 'badge',
  props: {
    color: {
      type: String,
      default: 'gray',
    },
  },
});
